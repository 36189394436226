<template>
  <v-select
    v-model="stateValue"
    :dense="dense"
    :items="status"
    clearable
    deletable-chips
    hide-details
    item-text="label"
    item-value="value"
    label="Statut"
    multiple
    outlined
    prepend-inner-icon="mdi-truck-fast-outline"
    small-chips
  >
    <template v-slot:item="{item, attrs, on}">
      <v-list-item
        #default="{ active }"
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-action>
          <v-checkbox :input-value="active" />
        </v-list-item-action>
        <v-list-item-title>
          <StatusChip :status="item.value" />
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
import StatusChip from '@/components/base/Shipment/StatusChip.vue'

export default {
  components: { StatusChip },
  props: {
    state: {
      type: Array,
      default: () => ([]),
    },
    dense: Boolean,
  },
  data() {
    return {
      status: [
        {
          label: this.$i18n.t('views.tracking.forms.enum.shipment_status.CREATED'),
          value:'CREATED',
        },
        {
          label: this.$i18n.t('views.tracking.forms.enum.shipment_status.IN_COLLECT'),
          value:'IN_COLLECT',
        },
        {
          label: this.$i18n.t('views.tracking.forms.enum.shipment_status.PACKED'),
          value:'PACKED',
        },
        {
          label: this.$i18n.t('views.tracking.forms.enum.shipment_status.AWAITING_LABEL_GENERATION'),
          value:'AWAITING_LABEL_GENERATION',
        },
        {
          label: this.$i18n.t('views.tracking.forms.enum.shipment_status.PENDING_DEPOSIT'),
          value:'PENDING_DEPOSIT',
        }, {
          label: this.$i18n.t('views.tracking.forms.enum.shipment_status.TAKEN_IN_CHARGE'),
          value:'TAKEN_IN_CHARGE',
        },
        {
          label: this.$i18n.t('views.tracking.forms.enum.shipment_status.IN_TRANSIT'),
          value:'IN_TRANSIT',
        }, {
          label: this.$i18n.t('views.tracking.forms.enum.shipment_status.DELIVERED'),
          value:'DELIVERED',
        },
        {
          label: this.$i18n.t('views.tracking.forms.enum.shipment_status.PICKUP_DELIVERED'),
          value:'PICKUP_DELIVERED',
        }, {
          label: this.$i18n.t('views.tracking.forms.enum.shipment_status.RETURNED'),
          value:'RETURNED',
        },
        {
          label: this.$i18n.t('views.tracking.forms.enum.shipment_status.IN_TROUBLE'),
          value:'IN_TROUBLE',
        },
        {
          label: this.$i18n.t('views.tracking.forms.enum.shipment_status.ARCHIVED'),
          value:'ARCHIVED',
        },
        {
          label: this.$i18n.t('views.tracking.forms.enum.shipment_status.DELETED'),
          value:'DELETED',
        },
      ],
    }
  },
  computed:{
    stateValue: {
      get () { return this.state  },
      set (value) { this.$emit('update:state', value) },
    },
  },
}
</script>

<style scoped>

</style>
