<template>
  <v-text-field
    v-model="weight"
    :label="inputLabel($t('views.products.forms.input.weight'), required)"
    :error-messages="errorData"
    suffix="g"
    type="number"
    hide-details
    clearable
    dense
    outlined
    @change="change"
  />
</template>

<script>
import inputMixin from '@/mixins/input'

export default {
  mixins: [ inputMixin ],
  props: {
    value: {
      type: Number,
      required: false,
      default: null,
    },
    category: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    required: Boolean,
  },
  data() {
    return {
      isDirty: false,
    }
  },
  computed: {
    weight: {
      get () { return this.value },
      set (value) { this.$emit('input', +value) },
    },
    errorData: {
      get () { return this.error },
      set (value) { this.$emit('update:error', value) },
    },
  },
  watch: {
    category (value) {
      const category = this.$store.getters.categories.find(cat => cat.id === value)
      if (!!category && (!this.weight || !this.isDirty)) {
        this.weight = category.weight
        this.errorData = null
      }
    },
  },
  methods: {
    change() {
      this.errorData = null
      this.isDirty = true
    },
  },
}
</script>
