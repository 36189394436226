<template>
  <v-chip
    class="mx-2 my-2"
    :class="getStatusColor(status)"
  >
    {{ $t('status.shipment.' + status.toLowerCase() + '.label') }}
  </v-chip>
</template>

<script>
export default {
  name: 'StatusChip',

  props: {
    status: {
      type: String,
      required: true,
    },
  },
  methods: {
    getStatusColor(status) {
      
      switch (status.toLowerCase()) {
      case 'created':
        return 'black--text blue lighten-4'
      case 'in_collect':
        return 'black--text blue lighten-3'
      case 'packed':
        return 'white--text blue lighten-2'
      case 'awaiting_label_generation':
        return 'white--text deep-orange darken-1'
      case 'pending_deposit':
        return 'white--text deep-orange lighten-1'
      case 'taken_in_charge':
        return 'white--text orange lighten-3'
      case 'in_transit':
        return 'white--text orange lighten-4'
      case 'pickup_delivered':
        return 'white--text green lighten-1'
      case 'delivered':
        return 'white--text green darken-1'
      case 'in_trouble':
        return 'white--text red lighten-2'
      case 'returned':
        return 'white--text red darken-1'
      case 'archived':
        return 'black--text grey lighten-3'
      case 'deleted':
        return 'black--text grey lighten-1'
      }
      
      return ''
    },
  },
}
</script>
