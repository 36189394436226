<template>
  <v-autocomplete
    v-model="target"
    :label="inputLabel($t('views.products.forms.input.target'), required)"
    :items="targets"
    :error-messages="error"
    hide-details
    clearable
    dense
    outlined
    item-text="name"
    item-value="id"
    @change="$emit('update:error', null)"
  />
</template>

<script>
import inputMixin from '@/mixins/input'
export default {
  mixins: [ inputMixin ],
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    required: Boolean,
  },
  computed: {
    target: {
      get () { return this.value },
      set (value) { this.$emit('input', value) },
    },
    targets () {
      return [{
        id: 'women',
        name: this.$t('views.products.forms.select_options.women'),
      }, {
        id: 'men',
        name: this.$t('views.products.forms.select_options.men'),
      }, {
        id: 'kids',
        name: this.$t('views.products.forms.select_options.kids'),
      }]
    },
  },
}
</script>
