<template>
  <v-autocomplete
    v-model="dropIds"
    :deletable-chips="!filter"
    :error-messages="error"
    :items="drops"
    :label="inputLabel($t('views.products.forms.input.drops'), required)"
    :multiple="!filter"
    :prepend-inner-icon="filter ? 'mdi-water-opacity' : ''"
    clearable
    dense
    hide-details
    item-text="fullName"
    item-value="id"
    outlined
    small-chips
    @change="$emit('update:error', null)"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="dropIds = dropIds.filter(id => id !== data.item.id)"
      >
        {{ data.item.fullName }}
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import inputMixin from '@/mixins/input'

export default {
  mixins: [ inputMixin ],
  props: {
    value: {
      type: Array,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    required: Boolean,
    filter: Boolean,
  },
  computed: {
    drops () {
      return this.$store.state.drops.map(drop => ({
        id: drop.id,
        shortName: drop.name,
        fullName: `${drop.name} (${drop.slug})`,
      }))
    },
    dropIds: {
      get () { return this.value },
      set (value) { this.$emit('input', value) },
    },
  },
}
</script>
