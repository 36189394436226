import axios from 'axios'
import jwt_decode from 'jwt-decode'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    logged: false,
    token: null,
    drawer: null,
    decodedToken: null,
    salePoints: [],
    categories: [],
    drops: [],
    families: [
      'vintage',
      'imperfect',
      'archive',
      'upcycled',
      'new_but_green',
    ],
    taxonomies: {
      colors: [],
      brands: [],
      years: [],
      states: [],
      styles: [],
      patterns: [],
      seasons: [],
      looks: [],
      materials: [],
    },
    carriers: [
      { default: true,  tracked: true, label: 'Colissimo',      value: 'COLISSIMO' },
      { default: true,  tracked: true, label: 'Mondial Relay',  value: 'MONDIAL_RELAY' },
      { default: true,  tracked: true, label: 'BPost',          value: 'BPOST' },
      { default: true,  tracked: true, label: 'Chronopost',     value: 'CHRONOPOST' },
      { default: false, tracked: true, label: 'DHL',           value: 'DHL' },
      { default: false, tracked: false, label: 'UPS',           value: 'UPS' },
      { default: false, tracked: true, label: 'Poste Italiane', value: 'POSTE_ITALIANE' },
      { default: false, tracked: true, label: 'GLS',            value: 'GLS' },
      { default: false, tracked: true, label: 'Colis Privé',    value: 'COLIS_PRIVE' },
      { default: false, tracked: false, label: 'Inconnu',        value: 'UNKNOWN' },
    ],
  },
  mutations: {
    login(state, token) {
      window.localStorage.setItem('token', token)
      state.token = token
      state.logged = true
      state.decodedToken = jwt_decode(token)
      state.roles = state.decodedToken.roles
    },
    logout(state) {
      window.localStorage.removeItem('token')
      state.token = null
      state.logged = false
    },
    drawerDisplay(state, display) {
      state.drawer = display
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories
    },
    SET_DROPS(state, drops) {
      state.drops = drops
    },
    SET_TAXONOMIES_COLORS(state,  taxonomies) {
      state.taxonomies.colors = taxonomies
    },
    SET_TAXONOMIES_BRANDS(state,  taxonomies) {
      state.taxonomies.brands = taxonomies
    },
    SET_TAXONOMIES_YEARS(state,  taxonomies) {
      state.taxonomies.years = taxonomies
    },
    SET_TAXONOMIES_STATES(state,  taxonomies) {
      state.taxonomies.states = taxonomies
    },
    SET_TAXONOMIES_STYLES(state,  taxonomies) {
      state.taxonomies.styles = taxonomies
    },
    SET_TAXONOMIES_PATTERNS(state,  taxonomies) {
      state.taxonomies.patterns = taxonomies
    },
    SET_TAXONOMIES_SEASONS(state,  taxonomies) {
      state.taxonomies.seasons = taxonomies
    },
    SET_TAXONOMIES_LOOKS(state,  taxonomies) {
      state.taxonomies.looks = taxonomies
    },
    SET_TAXONOMIES_MATERIALS(state,  taxonomies) {
      state.taxonomies.materials = taxonomies
    },
    SET_SALE_POINTS(state,  salePoints) {
      state.salePoints = salePoints
    },
  },
  actions: {
    loadSalePoints({ commit }) {
      Vue.axios.get('sale_points')
        .then(response => {
          let salePoints = response.data['hydra:member']
          salePoints.sort((a, b)=> (a.name > b.name ? 1 : -1))
          commit('SET_SALE_POINTS', salePoints)
        })
    },
    loadCategories({ commit }) {
      Vue.axios.get('v3/categories?depth=2&itemsPerPage=200')
        .then(response => {
          let categories = response.data['hydra:member']
          categories.sort((a, b)=> (a.parent.short_names.fr > b.parent.short_names.fr ? 1 : -1))
          commit('SET_CATEGORIES', categories)
        })
    },
    loadColors({ commit }) {
      Vue.axios.get('taxonomies?kind=color&itemsPerPage=200')
        .then(response => {
          let taxonomies = response.data['hydra:member']
          taxonomies.sort((a, b)=> (a.name > b.name ? 1 : -1))
          commit('SET_TAXONOMIES_COLORS', taxonomies)
        })
    },
    loadDrops({ commit }) {
      const GET_DROPS_GQL = `
        query drops {
          drops(allowUnpublished: true) {
            page(page: 1) {
              id
              slug
              name
            }
          }
        }
      `

      axios.post(process.env.VUE_APP_MARKETPLACE_GRAPHQL_URL, { query: GET_DROPS_GQL })
        .then(({ data }) => {
          let drops = data.data.drops.page
          commit('SET_DROPS', drops)
        })
    },
    loadBrands({ commit }) {
      Vue.axios.get('taxonomies?kind=brand&itemsPerPage=200')
        .then(response => {
          let taxonomies = response.data['hydra:member']
          taxonomies.sort((a, b)=> (a.name > b.name ? 1 : -1))
          commit('SET_TAXONOMIES_BRANDS', taxonomies)
        })
    },
    loadYears({ commit }) {
      Vue.axios.get('taxonomies?kind=years&itemsPerPage=200')
        .then(response => {
          let taxonomies = response.data['hydra:member']
          taxonomies.sort((a, b)=> (a.name > b.name ? 1 : -1))
          commit('SET_TAXONOMIES_YEARS', taxonomies)
        })
    },
    loadStates({ commit }) {
      Vue.axios.get('taxonomies?kind=state&itemsPerPage=200')
        .then(response => {
          let taxonomies = response.data['hydra:member']
          taxonomies.sort((a, b)=> (a.name > b.name ? 1 : -1))
          commit('SET_TAXONOMIES_STATES', taxonomies)
        })
    },
    loadStyles({ commit }) {
      Vue.axios.get('taxonomies?kind=style&itemsPerPage=200')
        .then(response => {
          let taxonomies = response.data['hydra:member']
          taxonomies.sort((a, b)=> (a.name > b.name ? 1 : -1))
          commit('SET_TAXONOMIES_STYLES', taxonomies)
        })
    },
    loadPatterns({ commit }) {
      Vue.axios.get('taxonomies?kind=pattern&itemsPerPage=200')
        .then(response => {
          let taxonomies = response.data['hydra:member']
          taxonomies.sort((a, b)=> (a.name > b.name ? 1 : -1))
          commit('SET_TAXONOMIES_PATTERNS', taxonomies)
        })
    },
    loadSeasons({ commit }) {
      Vue.axios.get('taxonomies?kind=season&itemsPerPage=200')
        .then(response => {
          let taxonomies = response.data['hydra:member']
          taxonomies.sort((a, b)=> (a.name > b.name ? 1 : -1))
          commit('SET_TAXONOMIES_SEASONS', taxonomies)
        })
    },
    loadLooks({ commit }) {
      Vue.axios.get('taxonomies?kind=look&itemsPerPage=200')
        .then(response => {
          let taxonomies = response.data['hydra:member']
          taxonomies.sort((a, b)=> (a.name > b.name ? 1 : -1))
          commit('SET_TAXONOMIES_LOOKS', taxonomies)
        })
    },
    loadMaterials({ commit }) {
      Vue.axios.get('taxonomies?kind=material&itemsPerPage=200')
        .then(response => {
          let taxonomies = response.data['hydra:member']
          taxonomies.sort((a, b)=> (a.name > b.name ? 1 : -1))
          commit('SET_TAXONOMIES_MATERIALS', taxonomies)
        })
    },
  },
  modules: {
  },
  getters: {
    families: (state) => state.families,
    carriers: (state) => state.carriers,
    categories: (state) => state.categories,
    drops: (state) => state.drops,
    taxonomiesColors: (state) => state.taxonomies.colors,
    taxonomiesBrands: (state) => state.taxonomies.brands,
    taxonomiesYears: (state) => state.taxonomies.years,
    taxonomiesStates: (state) => state.taxonomies.states,
    taxonomiesStyles: (state) => state.taxonomies.styles,
    taxonomiesPatterns: (state) => state.taxonomies.patterns,
    taxonomiesSeasons: (state) => state.taxonomies.seasons,
    taxonomiesLooks: (state) => state.taxonomies.looks,
    taxonomiesMaterials: (state) => state.taxonomies.materials,
    salePoints: (state) => state.salePoints,
    isLogged: state => {
      return state.token !== null && state.token !== undefined
    },
    isAdmin: state => state.decodedToken.roles.includes('ROLE_ADMINISTRATOR'),
    isInternalModerator: state => state.decodedToken.roles.includes('ROLE_INTERNAL_MODERATOR'),
    isExternalModerator: state => state.decodedToken.roles.includes('ROLE_EXTERNAL_MODERATOR'),
    roles: state => state.decodedToken.roles,
  },
})
