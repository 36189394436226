<template>
  <v-autocomplete
    v-model="categoryId"
    :items="categories"
    :label="inputLabel($t('views.products.forms.input.category'), required)"
    clearable
    deletable-chips
    dense
    hide-details
    item-text="short_names.fr"
    item-value="id"
    multiple
    outlined
    prepend-inner-icon="mdi-file-tree-outline"
    small-chips
  >
    <template v-slot:item="{item, attrs, on}">
      <v-list-item
        #default="{ active }"
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-action>
          <v-checkbox :input-value="active" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title v-text="item.short_names.fr" />
          <v-list-item-subtitle v-text="item.parent.short_names.fr" />
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import inputMixin from '@/mixins/input'
import { mapGetters } from 'vuex'

export default {
  mixins: [ inputMixin ],
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    required: Boolean,
  },
  computed: {
    ...mapGetters(['categories']),
    categoryId: {
      get () { return this.value },
      set (value) {this.$emit('input', value)
      },
    },
  },
}
</script>
