<template>
  <v-card
    class="mb-4"
  >
    <v-card-title v-if="showTitle">
      {{ $t('views.products.forms.titles.product_features') }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <TargetSelector
            v-model="form.target"
            :error.sync="errors.target"
            required
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <CategoryInput
            v-model="form.categoryId"
            :error.sync="errors.categoryId"
            required
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <FamilySelector
            v-model="form.family"
            :error.sync="errors.family"
            required
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <BrandSelector
            v-model="form.brandId"
            :error.sync="errors.brandId"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <ConditionSelector
            v-model="form.conditionId"
            :error.sync="errors.conditionId"
            required
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <ColorsSelector
            v-model="form.colorIds"
            :error.sync="errors.colorIds"
            required
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <MaterialsSelector
            v-model="form.materialIds"
            :error.sync="errors.materialIds"
            required
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <LookSelector
            v-model="form.lookId"
            :error.sync="errors.lookId"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <YearsSelector
            v-model="form.yearsId"
            :error.sync="errors.yearsId"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <PatternsSelector
            v-model="form.patternIds"
            :error.sync="errors.patternIds"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <StylesSelector
            v-model="form.styleIds"
            :error.sync="errors.styleIds"
          />
        </v-col>
        <v-col
          v-if="!updateMode"
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <StoreSelector
            v-model="form.storeId"
            :error.sync="errors.storeId"
            required
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <WeightInput
            v-model="form.weight"
            :category="form.categoryId"
            :error.sync="errors.weight"
            required
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <DropsSelector
            v-model="form.dropIds"
            :error.sync="errors.dropIds"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import BrandSelector from '@/components/base/Product/Input/BrandSelector'
import CategoryInput from '@/components/base/Product/Input/CategoryInput'
import ColorsSelector from '@/components/base/Product/Input/ColorsSelector'
import ConditionSelector from '@/components/base/Product/Input/ConditionSelector'
import DropsSelector from '@/components/base/Product/Input/DropsSelector'
import FamilySelector from '@/components/base/Product/Input/FamilySelector'
import LookSelector from '@/components/base/Product/Input/LookSelector'
import MaterialsSelector from '@/components/base/Product/Input/MaterialsSelector'
import PatternsSelector from '@/components/base/Product/Input/PatternsSelector'
import StoreSelector from '@/components/base/StoreSelector.vue'
import StylesSelector from '@/components/base/Product/Input/StylesSelector'
import TargetSelector from '@/components/base/Product/Input/TargetSelector.vue'
import WeightInput from '@/components/base/Product/Input/WeightInput'
import YearsSelector from '@/components/base/Product/Input/YearsSelector'

export default {
  components: {
    TargetSelector,
    MaterialsSelector,
    CategoryInput,
    DropsSelector,
    FamilySelector,
    BrandSelector,
    ConditionSelector,
    ColorsSelector,
    LookSelector,
    YearsSelector,
    PatternsSelector,
    StylesSelector,
    StoreSelector,
    WeightInput,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    showTitle: Boolean,
    updateMode: Boolean,
    errors: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
