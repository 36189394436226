<template>
  <v-select
    v-model="stateValue"
    :deletable-chips="multiple"
    :dense="dense"
    :items="carriers"
    :multiple="multiple"
    clearable
    hide-details
    item-text="label"
    item-value="value"
    label="Transporteur"
    outlined
    prepend-inner-icon="mdi-transit-connection-variant"
    small-chips
  >
    <template v-slot:item="{item, attrs, on}">
      <v-list-item
        #default="{ active }"
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-action>
          <v-checkbox :input-value="active" />
        </v-list-item-action>
        <v-list-item-icon>
          <CarrierIcon :carrier="item.value" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.label }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
import CarrierIcon from '@/components/base/Shipment/CarriererIcon.vue'

export default {
  components: { CarrierIcon },
  props: {
    state: {
      type: [String, Array],
      default: () => ([]),
    },
    dense: Boolean,
    multiple: Boolean,
    defaultOnly: Boolean,
  },
  computed:{
    carriers () {
      const carriers = this.defaultOnly === true ?
        this.$store.getters.carriers.filter(v => v.default === true)
        : this.$store.getters.carriers
      
      return carriers.map(v => {
        return {
          value: v.value,
          label: v.label,
        }
      })
    },
    stateValue: {
      get () { return this.state  },
      set (value) { this.$emit('update:state', value) },
    },
  },
}
</script>

<style scoped>

</style>
